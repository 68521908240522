const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/config.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Button } from "antd";
import Parse from "parse";
import React from "react";
import ArtikelBuchungComponent from "./custom_wehn/ArtikelBuchungComponent";
import ArtikelInfoComponent from "./custom_wehn/ArtikelInfoComponent";
import CustomerComponent from "./custom_arfa/CustomerComponent";
import ShelfComponent from "./custom_arfa/ShelfComponent";
import ReportSelectComponent from "./custom_arfa/ReportSelectComponent";
import { useNavigate } from "react-router";

const getKPZManageComponent = (path) => {
  return () => {
    const navigate = useNavigate();
    return (
      React.createElement('div', { style: { height: "100vh", width: "100vw" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , React.createElement(Button, {
          type: "primary",
          style: { height: "3%", width: "100%" },
          onClick: () => {
            navigate("/select");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
        , "Zurück"

        )
        , React.createElement('iframe', {
          style: { height: "97%", width: "100%" },
          src: `https://opendash.kompetenzzentrum-siegen.digital/admin/bde/${path}?embedded=true&session=${
            _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.getSessionToken, 'call', _5 => _5()]) || ""
          }`,
          title: "Formulare", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        )
      )
    );
  };
};
const kpz = {
  logo: "https://kompetenzzentrum-siegen.digital/wp-content/uploads/2018/02/logokomprimiert.png",
  menuItems: [
    {
      label: "Einheiten für Formulare konfigurieren",
      description: "Formulare anlegen und anpassen",
      target: "/manage/units",
    },
    {
      label: "Formulare konfigurieren",
      description: "Formulare anlegen, anpassen und Seiten zuordnen",
      target: "/manage/forms",
    },
    {
      label: "Formularseiten konfigurieren",
      description: "Seiten anlegen und anpassen",
      target: "/manage/entries",
    },
  ],
  referenceStrings: [
    "Auftrag",
    "Auftragsnummer",
    "Bitte geben Sie eine Auftragsnummer an",
    "Auftragsbezug",
    "Auftr:",
  ],
  routes: [
    {
      path: "/manage/units",
      component: getKPZManageComponent("units"),
    },
    {
      path: "/manage/forms",
      component: getKPZManageComponent("forms"),
    },
    {
      path: "/manage/entries",
      component: getKPZManageComponent("entries"),
    },
  ],
  qrEnabled: true,
  parse: {
    url: "https://users.kompetenzzentrum-siegen.digital/parse",
    appid: "1234567890",
  },

  units: {
    table: "rueckmeldeeinheit",
    field: "name",
    //filter: "auftrag",
    source: "source",
    get: async (ref) => {
      //let conf = await Parse.Config.get();
      //let forms = conf.get("forms");
      let formQuery = new Parse.Query("rueckmeldeeinheit");
      //formQuery.containedIn("RID", forms);
      let results = await formQuery.find();
      return results.map((form) => form.toJSON());
    },
  },
  forms: {
    table: "rueckmeldung",
    get: async (unit, ref) => {
      if (!unit) throw Error("Unit needs to be provided");
      let x = await new Parse.Object("rueckmeldeeinheit");
      x.id = unit.objectId;
      await x.fetch();
      const result = await x.relation("forms").query().find();

      return result.map((form) => form.toJSON());
    },
  },
  pages: {
    table: "rueckmeldeeintrag",
  },
  lists: {
    table: "BDE_Listen",
    children: "BDE_Listeneintraege",
  },
  results: {
    table: "rueckmeldung_result",
  },
};

const arfa = {
  qrEnabled: false,
  parse: {
    url: "https://users.kompetenzzentrum-siegen.digital/parse",
    appid: "1234567890",
  },
  references: {
    table: "ARFA_CUSTOMER",
    field: "name",
    label: ["name"],
  },
  referenceStrings: [
    "Kunde",
    "Kunden",
    "Bitte wählen Sie einen Kunden aus",
    "Kundenbezug",
    "Kunde:",
  ],
  menuItems: [
    {
      label: "Kunden Management",
      description: "Kunden anlegen und bearbeiten",
      target: "/customer",
    },
    {
      label: "Regal Management",
      description: "Regale für Kunden anlegen",
      target: "/shelf",
    },
    {
      label: "Report Management",
      description: "Reporte erzeugen",
      target: "/report",
    },
  ],
  routes: [
    {
      path: "customer/*",
      component: () => {
        return React.createElement(CustomerComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 159}} );
      },
    },
    {
      path: "shelf/*",
      component: () => {
        return React.createElement(ShelfComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 165}} );
      },
    },
    {
      path: "report/*",
      component: () => {
        return React.createElement(ReportSelectComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 171}} );
      },
    },
  ],
  units: {
    table: "rueckmeldeeinheit",
    field: "name",
    //filter: "auftrag",
    source: "source",
    get: async (ref) => {
      //let conf = await Parse.Config.get();
      //let forms = conf.get("forms");
      let formQuery = new Parse.Query("rueckmeldeeinheit");
      //formQuery.containedIn("RID", forms);

      let results = await formQuery.find();
      return results.map((form) => form.toJSON());
    },
  },
  forms: {
    table: "rueckmeldung",
    get: async (unit, ref) => {
      if (!unit) throw Error("Unit needs to be provided");
      let x = await new Parse.Object("rueckmeldeeinheit");
      x.id = unit.objectId;
      await x.fetch();
      const result = await x.relation("forms").query().find();

      return result.map((form) => form.toJSON());
    },
  },
  pages: {
    table: "rueckmeldeeintrag",
  },
  lists: {
    table: "BDE_Listen",
    children: "BDE_Listeneintraege",
  },
  results: {
    table: "rueckmeldung_result",
  },
};

const w = {
  logo: "https://www.fahrzeugtechnik.de/html/img/logo_wehn-fahrzeugtechnik.png",
  menuItems: [
    {
      label: "Entnahme",
      description: "Entnehmen / Rücklagern von Artikeln",
      target: "/select",
    },
    {
      label: "ArtikelInfo",
      target: "/artikel",
    },
  ],
  routes: [
    {
      path: "entnahme/:type/:id/*",
      component: () => {
        return React.createElement(ArtikelBuchungComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 231}} );
      },
    },
    {
      path: "artikel/*",
      component: () => {
        return React.createElement(ArtikelInfoComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 237}} );
      },
    },
  ],

  qrEnabled: false,
  forms: {
    table: "BDE_Form",
    conf: null,
    get: async (unit, ref) => {
      if (!w.conf) w.conf = await Parse.Config.get();
      let forms = w.conf.get(unit.className);

      let formQuery = new Parse.Query("BDE_Form");
      formQuery.containedIn("RID", forms);
      let toReturn = await formQuery.find();
      toReturn = toReturn.map((form) => {
        let x = form.toJSON();
        x.className = form.className;
        return x;
      });

      return toReturn;
    },
  },
  pages: {
    table: "BDE_Page",
  },
  parse: {
    url: "https://parse-wehn.apps.openinc.dev/parse",
    appid: "wehn",
  },
  references: {
    table: "Auftrag",
    field: "auftragsNummer",
    label: ["auftragsNummer", "bezeichnung"],
  },
  referenceStrings: [
    "Auftrag",
    "Auftragsnummer",
    "Bitte geben Sie eine Auftragsnummer an",
    "Auftragsbezug",
    "Auftr.",
  ],
  units: {
    table: "Auftragsstueckliste",
    field: "bezeichnung",
    filter: "auftrag",
    source: "objectId",
    orderby: "BDENummer",

    get: async (reference) => {
      const query = new Parse.Query("Auftragsstueckliste");
      const query2 = new Parse.Query("Auftragsposition");
      const querycompare = new Parse.Query("Auftrag");
      querycompare.equalTo("auftragsNummer", reference);
      let x = await querycompare.first();

      query.equalTo("auftrag", x);
      query.ascending("BDENummer");
      query.equalTo("hierarchyParent", "0");
      query.include("auftragPosition");

      query2.equalTo("auftrag", x);
      query2.notEqualTo("dispoArt", "F");
      let posCount = await query2.count();

      let stcklisten = await query.find();
      stcklisten = stcklisten.map((list) => {
        let x = list.toJSON();
        x.className = list.className;
        return x;
      });
      //let aufpositionen = await query2.find();

      if (posCount > 0) {
        stcklisten.push({
          className: "Auftragsposition",
          bezeichnung: "Auftragspositionen",
        });
      }
      return stcklisten;
    },
  },
  lists: {
    table: "BDE_Listen",
    children: "BDE_Listeneintraege",
  },
  results: {
    table: false,
  },
};
export default arfa;
