const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/forms/components/CheckBox.js";import React, { } from "react";

import { Form, Checkbox } from "antd";


const CheckboxGroup = Checkbox.Group;

function CheckBox(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  let state = props.Data;
  let setValue = props.SetValue;
  let options = state.options || [];
  let items = state.items || [];
  let rules = [];

  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  var plainOptions = items;

  return (
    React.createElement(Form.Item, { name: state.key, label: state.title, rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , React.createElement(CheckboxGroup, { style: style, options: plainOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    )
  );
}
export default CheckBox;
