const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/custom_wehn/AuftragspositionComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState, } from "react";
import Parse from "parse";
import produce from "immer";

import {
  createArtikelTransaction,
  undoArtikelTransaction,
  getListItem,
} from "./common";
import Loading from "../components/Loading";
import { Button, Result, List } from "antd";

import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import ArtikelBuchungDrawerComponent from "./ArtikelBuchungDrawerComponent";

export default function AuftragspositionComponent(props) {
  if (props.ShowNextButton()) {
    props.ShowNextButton(false);
  }

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ref, setRef] = useState(searchParams.get("reference"));
  const [state, setState] = React.useState({
    position: undefined,
  });

  // console.log("AuftragspositionComponent params", params);
  // console.log("AuftragspositionComponent searchParams", searchParams);
  // console.log("AuftragspositionComponent props", props);
  // console.log("AuftragspositionComponent state", state);

  const save = async (data) => {
    for (let removal of data.removals) {
      await createArtikelTransaction(removal);
    }

    for (let restock of data.restocked) {
      await undoArtikelTransaction(restock, restock.menge);
    }

    await fetchAuftragsposition();
    setState(
      produce((draft) => {
        draft.selectedItem = undefined;
      })
    );
  };
  const selectItem = (item) => {
    //O-Teil
    if (!item.artikel) return;

    //Position gesperrt
    if (item.gesperrt) return;

    navigate(`/entnahme/Auftragsposition/${item.objectId}`);
    /*
    setState(
      produce((draft) => {
        draft.selectedItem = { ...item };
      })
    );
    */
  };
  const [lagerOrte, setLagerOrte] = React.useState({});
  const getLagerOrt = async () => {
    let result = {};
    for (let pos of state.position) {
      if (!pos.artikel) {
        continue;
      }
      const artikel = new Parse.Object("Artikel", {
        objectId: pos.artikel.objectId,
      });
      let lagerQuery = new Parse.Query("ArtikelEinlagerung");
      lagerQuery.equalTo("artikel", artikel);
      lagerQuery.include("lager");
      lagerQuery.include("lagerort");
      let einlagerungen = await lagerQuery.find();

      let bestandQuery = new Parse.Query("ArtikelBestand");
      bestandQuery.include("lager");
      bestandQuery.equalTo("artikel", artikel);
      let bestaende = await bestandQuery.find();

      for (const bestand of bestaende) {
        const artikelId = _optionalChain([bestand, 'access', _ => _.get, 'call', _2 => _2("artikel"), 'optionalAccess', _3 => _3.id]);
        const lagerNr = parseInt(_optionalChain([bestand, 'access', _4 => _4.get, 'call', _5 => _5("lager"), 'optionalAccess', _6 => _6.get, 'call', _7 => _7("sqlRef")]));

        result[artikelId] = {
          lagerNr,
          lager: bestand.get("lager").get("sqlRef"),
          lagerName: bestand.get("lager").get("bezeichnung"),
        };
      }
      for (const einlagerung of einlagerungen) {
        const artikelId = einlagerung.get("artikel").id;
        const lagerNr = parseFloat(
          JSON.parse(
            _optionalChain([einlagerung, 'access', _8 => _8.get, 'call', _9 => _9("lagerort"), 'optionalAccess', _10 => _10.get, 'call', _11 => _11("sqlRef")]) || "[0,0]"
          ).join(".")
        );

        result[artikelId] = {
          lagerNr,
          lagerName: einlagerung.get("lager").get("bezeichnung"),
          lagerOrt:
            _optionalChain([einlagerung, 'access', _12 => _12.get, 'call', _13 => _13("lagerort"), 'optionalAccess', _14 => _14.get, 'call', _15 => _15("lagerort")]) || "Kein Lagerort",
          lager: einlagerung.get("lager").get("sqlRef"),
        };
      }
    }
    setLagerOrte(result);
  };
  React.useEffect(() => {
    if (_optionalChain([state, 'access', _16 => _16.position, 'optionalAccess', _17 => _17.length]) || 0 > 0) {
      getLagerOrt();
    }
  }, [state.position]);

  const subscribe = async () => {
    const auftrag = await new Parse.Query("Auftrag")
      .equalTo("sqlRef", ref)
      .first();
    const livequery = new Parse.Query("Auftragsposition")
      .equalTo("auftrag", auftrag)
      .containedIn("dispoArt", ["K", "E"]);
    let subscription = await livequery.subscribe();
    const liveUdpate = (object) => {
      fetchAuftragsposition();
    };
    subscription.on("update", liveUdpate);
    subscription.on("create", liveUdpate);
  };
  React.useEffect(() => {
    fetchAuftragsposition();
    subscribe();
  }, [ref]);

  async function fetchAuftragsposition() {
    try {
      const auftrag = await new Parse.Query("Auftrag")
        .equalTo("sqlRef", ref)
        .first();

      const auftragspositionen1 = new Parse.Query("Auftragsposition")
        .equalTo("auftrag", auftrag)
        .containedIn("dispoArt", ["K", "E"])
        .include("auftrag")
        .greaterThan("mengeBereitgestellt", 0)
        .include("artikel");
      const auftragspositionen2 = new Parse.Query("Auftragsposition")
        .equalTo("auftrag", auftrag)
        .containedIn("dispoArt", ["K", "E"])
        .include("auftrag")
        .greaterThan("mengeAngefordert", 0)
        .include("artikel");
      const auftragspositionen = await new Parse.Query.or(
        auftragspositionen1,
        auftragspositionen2
      ).find();

      if (!auftragspositionen) {
        throw new Error("Position not found");
      }
      const positionen = await Promise.all(
        auftragspositionen.map((auftragsposition) => {
          return async () => {
            const removals = await new Parse.Query("open_transaction_artikel")
              .equalTo("auftragposition", auftragsposition)
              .equalTo("successful", true)
              .include("user")
              .descending("updatedAt")
              .include("artikel")
              .find();

            let cPos = auftragsposition.toJSON();
            cPos.removals = removals.map((parseObject) => parseObject.toJSON());
            if (cPos.artikel) {
              let artikelnr = auftragsposition
                .get("artikel")
                .get("artikelnummer");
              if (cPos.artikel.__type === "Pointer") {
                const artikelToFetch = await new Parse.Object("Artikel", {
                  id: cPos.artikel.objectId,
                }).fetch();
                artikelnr = artikelToFetch.get("artikelnummer");
              }

              cPos.artikelnr = artikelnr;
            }

            return cPos;
          };
        })
      );

      setState(
        produce((draft) => {
          draft.position = positionen;

          delete draft.error;
        })
      );
    } catch (error) {
      console.error("StockRemovalPosition: Could not retrieve Position", error);

      setState(
        produce((draft) => {
          delete draft.position;
          draft.error = {
            status: "warning",
            title: "Positionen konnten nicht gefunden werden",
          };
        })
      );
    }
  }

  const sortItems = (item1, item2) => {
    const doneFlag1 =
      item1.fertig &&
      !(
        (item1.mengeAngefordertNichtBestellt > 0 ||
          item1.mengeBestelltWehn > 0) &&
        item1.mengeIst == 0 &&
        item1.mengeSoll == 0
      );
    const mailFlag1 =
      item1.mengeAngefordertNichtBestellt > 0 || item1.mengeBestelltWehn > 0;
    const gesperrtFlag1 =
      !(
        item1.mengeAngefordertNichtBestellt > 0 || item1.mengeBestelltWehn > 0
      ) &&
      item1.gesperrt &&
      !item1.fertig;

    const doneFlag2 =
      item2.fertig &&
      !(
        (item2.mengeAngefordertNichtBestellt > 0 ||
          item2.mengeBestelltWehn > 0) &&
        item2.mengeIst == 0 &&
        item2.mengeSoll == 0
      );
    const mailFlag2 =
      item2.mengeAngefordertNichtBestellt > 0 || item2.mengeBestelltWehn > 0;
    const gesperrtFlag2 =
      !(
        item2.mengeAngefordertNichtBestellt > 0 || item2.mengeBestelltWehn > 0
      ) &&
      item2.gesperrt &&
      !item2.fertig;

    const begonnenFlag1 = !item1.fertig && item1.mengeIst > 0;
    const begonnenFlag2 = !item2.fertig && item2.mengeIst > 0;

    const oteilFlag1 = !item1.artikel;
    const oteilFlag2 = !item2.artikel;

    const keinBestandFlag1 =
      !doneFlag1 &&
      !begonnenFlag1 &&
      !_optionalChain([lagerOrte, 'access', _18 => _18[_optionalChain([item1, 'access', _19 => _19.artikel, 'optionalAccess', _20 => _20.objectId])], 'optionalAccess', _21 => _21.lagerNr]);
    const keinBestandFlag2 =
      !doneFlag2 &&
      !begonnenFlag2 &&
      !_optionalChain([lagerOrte, 'access', _22 => _22[_optionalChain([item2, 'access', _23 => _23.artikel, 'optionalAccess', _24 => _24.objectId])], 'optionalAccess', _25 => _25.lagerNr]);

    if (gesperrtFlag1 !== gesperrtFlag2) {
      if (gesperrtFlag1) return 1;
      return -1;
    }
    if (doneFlag1 !== doneFlag2) {
      if (doneFlag1) return 1;
      return -1;
    }
    if (oteilFlag1 !== oteilFlag2) {
      if (oteilFlag1) return 1;
      return -1;
    }
    if (mailFlag1 !== mailFlag2) {
      if (mailFlag1) return 1;
      return -1;
    }
    if (keinBestandFlag1 !== keinBestandFlag2) {
      if (keinBestandFlag1) return 1;
      return -1;
    }
    if (begonnenFlag1 !== begonnenFlag2) {
      if (begonnenFlag1) return 1;
      return -1;
    }

    const lager1 = _optionalChain([lagerOrte, 'access', _26 => _26[_optionalChain([item1, 'optionalAccess', _27 => _27.artikel, 'optionalAccess', _28 => _28.objectId])], 'optionalAccess', _29 => _29.lagerNr]) || 0;
    const lager2 = _optionalChain([lagerOrte, 'access', _30 => _30[_optionalChain([item2, 'optionalAccess', _31 => _31.artikel, 'optionalAccess', _32 => _32.objectId])], 'optionalAccess', _33 => _33.lagerNr]) || 0;
    //TODO if lager1 - lager2 ==0 -> Artikelnr
    return lager1 - lager2;
  };

  if (state.error) {
    return (
      React.createElement(Result, {
        status: state.error.status,
        title: state.error.title,
        extra: 
          React.createElement(Button, {
            type: "primary",
            key: "back",
            onClick: () => {
              navigate("/select");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 310}}
          , "zurück"

          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 306}}
      )
    );
  }

  if (!state.position) {
    return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 325}} );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, {
        style: { background: "#fff", padding: "12px" },
        itemLayout: "vertical",
        dataSource: state.position
          .filter((item) => {
            return (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf((state.filter || "").toLowerCase()) != -1
            );
          })
          .sort(sortItems),
        renderItem: (item) => {
          return getListItem(item, lagerOrte, selectItem);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 330}}
      )

      , React.createElement(ArtikelBuchungDrawerComponent, {
        selectedItem: state.selectedItem,
        removals: _optionalChain([state, 'access', _34 => _34.selectedItem, 'optionalAccess', _35 => _35.removals]) || [],
        update: save,
        onClose: () => {
          setState(
            produce((draft) => {
              draft.selectedItem = undefined;
            })
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}
      )
    )
  );
}
