const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/custom_arfa/ReportSelectComponent.js"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse, { } from "parse";
import moment from "moment";
import { useParams, } from "react-router-dom";
import { PageHeader, Select, Button } from "antd";


export default function ReportSelectComponent(props) {
  const params = useParams();

  const [selectedReport, setSelectedReport] = React.useState(99);
  const [reports, setReports] = React.useState([]);

  const fetchReports = async () => {
    try {
      const needFormID = "form1625729547836";
      const currentCustomer = localStorage.getItem("openchecklistref");
      const resultList = await new Parse.Query("rueckmeldung_result")
        .equalTo("RID", needFormID)
        .equalTo("result.reference", currentCustomer)
        .descending("createdAt")
        .limit(999999)
        .find();

      let resultsArray = [];
      for (let i = 0; i < resultList.length; i++) {
        let resultJSON = resultList[i].toJSON();

        const shelfParse = await new Parse.Query("ARFA_SHELF")
          .equalTo("objectId", resultJSON.result.pages.step1.fields[0].value)
          .first();

        if (shelfParse) {
          resultJSON.shelfnumber = shelfParse.get("Regalnummer");
        } else {
          resultJSON.shelfnumber = "Regal nicht mehr vorhanden";
        }

        resultsArray.push(resultJSON);
      }

      setReports(resultsArray);
    } catch (error) {
      setReports([]);
    }
  };

  const getDate = (date) => {
    return moment(date).format("DD.MM.YYYY - HH:mm:ss") + "";
  };

  const getReport = async () => {
    const needFormID = "form1625729547836";
    const currentCustomer = localStorage.getItem("openchecklistref");

    const customerParse = await new Parse.Query("ARFA_CUSTOMER")
      .equalTo("name", currentCustomer)
      .first();

    //Customer
    let betreiber = customerParse.get("name");
    let contact = customerParse.get("contact");
    let contacttel = customerParse.get("contacttel");
    let saftycontact = customerParse.get("saftycontact");

    const resultList = await new Parse.Query("rueckmeldung_result")
      .equalTo("objectId", selectedReport)
      .equalTo("result.reference", currentCustomer)
      .descending("createdAt")
      .limit(999999)
      .find();

    const shelfParse = await new Parse.Query("ARFA_SHELF")
      .equalTo(
        "objectId",
        resultList[0].get("result").pages.step1.fields[0].value
      )
      .first();

    //Shelf
    let betriebstaette = shelfParse.get("Betriebsstaette")
      ? shelfParse.get("Betriebsstaette")
      : "";
    let abteilung = shelfParse.get("Abteilung")
      ? shelfParse.get("Abteilung")
      : "";
    let shelfnumber = shelfParse.get("Regalnummer")
      ? shelfParse.get("Regalnummer")
      : "";
    let shelftype = shelfParse.get("Art") ? shelfParse.get("Art") : "";
    let shelfname = shelfParse.get("name") ? shelfParse.get("name") : "";
    let shelfcompany = shelfParse.get("shelfcompany")
      ? shelfParse.get("shelfcompany")
      : "";

    const savedTime = resultList[0].get("result").saveDate;
    let startTime = savedTime - 1;
    let endTime = savedTime + 1;
    let source = "glombik";
    let form = source + needFormID;
    let url =
      "https://opendash.kompetenzzentrum-siegen.digital/api/report/generic";

    let body = {
      params: {
        title: "Report",
        description: "Beschreibung",
        template: "generic.docx",
        masterData: {
          regal: {
            betreiber: betreiber,
            betriebstaette: betriebstaette,
            abteilung: abteilung,
            contact: contact,
            contacttel: contacttel,
            shelfcompany: shelfcompany,
            saftycontact: saftycontact,
            shelfnumber: shelfnumber,
            shelftype: shelftype,
            shelfname: shelfname,
            date: getDate(resultList[0].get("createdAt")),
          },
        },
        items: [
          {
            reportid: "result",
            stages: [
              {
                action: "historical",
                params: {
                  start: startTime,
                  end: endTime,
                  source: source,
                  id: form,
                },
              },
            ],
          },
        ],
      },
    };

    //Post and Download:
    let linkRef = React.createRef();
    const requestOptions = {
      method: "POST",
      headers: { "OD-SESSION": _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.getSessionToken, 'call', _5 => _5()]) },
      body: JSON.stringify(body),
    };
    fetch(url, requestOptions)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Report.docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    fetchReports();
  }, []);

  return (
    React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
      , _nullishCoalesce(props.header, () => ( (
        React.createElement(PageHeader, {
          onBack: () => {
            window.history.back();
          },
          title: "Report Management",
          subTitle: "Report auswählen und herunterladen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
        )
      )))
      , React.createElement('div', {
        style: { padding: "2px", textAlign: props.center ? "center" : "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
      
        , React.createElement('div', {
          style: {
            padding: "2px",
            paddingLeft: props.center ? "15%" : "10px",
            paddingRight: props.center ? "15%" : "10px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
        
          , React.createElement('div', { style: { padding: "2px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
            , React.createElement('h3', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}, "Report exportieren:" )
            , React.createElement(Select, {
              placeholder: "Report auswählen" ,
              style: { width: "100%", height: "42px" },
              value: selectedReport,
              onChange: (e) => {
                setSelectedReport(e);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
            
              , React.createElement(Select.Option, { key: 99, value: 99, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}, "Bitte Report auswählen"

              ), "|"

              , reports.length > 0 &&
                reports.map((item) => (
                  React.createElement(Select.Option, { key: item.objectId, value: item.objectId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
                    , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}
                      , getDate(item.createdAt), " - Regalnummer:"  , " "
                      , item.shelfnumber
                    )
                  )
                ))
            )
            , React.createElement('div', { style: { height: "5px", width: "75%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}})
            , React.createElement(Button, {
              style: { width: "100%", height: "32px" },
              disabled: selectedReport === 99,
              onClick: () => {
                getReport();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
            , "Bericht erstellen"

            )
          )
        )
      )
    )
  );
}
