const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/forms/FormList.js";import React, { useState, } from "react";
import produce from "immer";
import Parse from "parse";
import { UnorderedListOutlined, } from "@ant-design/icons";
import { List, Divider, } from "antd";
import { useNavigate } from "react-router-dom";
import config from "../config";

function FormList(props) {
  const navigate = useNavigate();

  /*
   *
   *
   */

  const [formCount, setFormCount] = useState({
    number: {},
  });

  const getItemCount = async (rid, user, id, source) => {
    const itemObj = Parse.Object.extend(config.results.table);
    const query = new Parse.Query(itemObj);
    query.equalTo("RID", rid);
    query.equalTo("result.reference", id);
    query.equalTo("result.source", source);
    //query.equalTo("result.User.objectId", user);
    const results = await query.find();
    setFormCount(
      produce((draft) => {
        draft.number[rid] = results.length;
      })
    );
  };

  React.useEffect(() => {
    if (config.results.table) {
      for (var i = 0; i < props.Unit.forms.length; i++) {
        getItemCount(
          props.Unit.forms[i].RID,
          Parse.User.current().id,
          props.Reference,
          props.Unit.source
        );
      }
    }
  }, []);

  /*
   *
   *
   */

  return (
    React.createElement('div', { style: props.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      , React.createElement('div', {
        style: {
          backgroundColor: "#fff",
          width: "100%",
          textAlign: "center",
          paddingTop: "5px",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      
        , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}})
        , React.createElement('p', { style: { margin: "0px", paddingTop: "5px", fontSize: "18px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, props.Unit[config.units.field])
        )
        , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}})
      )
      , React.createElement(List, {
        style: { backgroundColor: "#f5f2f2" },
        itemLayout: "horizontal",
        dataSource: props.Unit.forms,
        renderItem: (item, index) => {
          return (
            React.createElement(List.Item, {
              key: "key-" + index,
              onClick: () => {
                navigate(
                  "/form/" +
                    props.Unit[config.units.source] +
                    "/" +
                    item.RID +
                    "/0" +
                    "?reference=" +
                    props.Reference
                );
              },
              style: { padding: "14px", width: "100%" },
              key: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
            
              , React.createElement('div', { style: { width: "15%", float: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
                , React.createElement(UnorderedListOutlined, {
                  style: { height: "100%", fontSize: "30px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
                )
              )
              , React.createElement('div', { style: { width: "75%", float: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                , React.createElement('div', {
                  style: {
                    width: "100%",
                    height: "27px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
                
                  , item.name || "Kein Titel"
                )
                , React.createElement('div', { style: { width: "100%", fontSize: "13px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                  , item.description || "Keine Beschreibung"
                )
              )
              , formCount.number && formCount.number.hasOwnProperty(item.RID) ? (
                React.createElement('div', {
                  style: {
                    width: "35px",
                    height: "35px",
                    maxWidth: "10%",
                    textAlign: "center",
                    borderLeft: "2px solid #096dd9",
                    padding: "5px",
                    color: "#096dd9",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
                
                  , formCount.number[item.RID]
                )
              ) : (
                React.createElement('div', {
                  style: {
                    width: "35px",
                    height: "35px",
                    maxWidth: "10%",
                    textAlign: "center",
                    padding: "5px",
                    color: "#096dd9",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
                )
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      )
    )
  );
}
export default FormList;
