const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/forms/components/TextInput.js";import React, { } from "react";

import { Form, Input, Typography } from "antd";


const { Text } = Typography;
const { TextArea } = Input;

function TextInput(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  let state = props.Data;
  let options = state.options || {};
  let rules = [];
  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }
  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        let re = new RegExp(options.validation.expression);
        if (!re.test(value)) return Promise.reject(options.validation.err);
        return Promise.resolve();
      },
    }));
  }
  let inputelement = React.createElement(Input, { style: style, placeholder: state.hint, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} );
  if (state.options.area) {
    inputelement = React.createElement(TextArea, { rows: 4, style: style, placeholder: state.hint, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} );
  }

  return (
    React.createElement(Form.Item, { name: state.key, label: state.title, rules: rules, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      , inputelement
    )
  );
}
export default TextInput;
