const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/custom_arfa/ShelfComponent.js";import React, { } from "react";
import Parse, { } from "parse";

import { useParams, } from "react-router-dom";
import { Input, PageHeader, Select, Button, notification } from "antd";

export default function ShelfComponent(props) {
  const params = useParams();
  const [customerList, setCustomerList] = React.useState([]);
  const [customer, setCustomer] = React.useState({
    id: "",
    name: "",
    contact: "",
    contacttel: "",
    saftycontact: "",
  });
  const [shelf, setShelf] = React.useState({
    id: "XXX",
    name: "",
    betriebstatte: "",
    nummer: "",
    abteilung: "",
    art: "",
    customer: "",
    shelfcompany: "",
  });

  const fetchCustomer = async () => {
    try {
      const cCustomer = new Parse.Query("ARFA_CUSTOMER");
      const customers = await cCustomer.find();
      let resultsArray = [];
      for (let i = 0; i < customers.length; i++) {
        resultsArray.push(customers[i].toJSON());
      }
      setCustomerList(resultsArray);
    } catch (error) {
      setCustomerList(null);
    }
  };

  const saveEntry = async () => {
    try {
      const SHELFC = Parse.Object.extend("ARFA_SHELF");
      const shelf_obj = new SHELFC();

      shelf_obj.set("name", shelf.name ? shelf.name : "");
      shelf_obj.set(
        "Betriebsstaette",
        shelf.betriebstatte ? shelf.betriebstatte : ""
      );
      shelf_obj.set("Regalnummer", shelf.nummer);
      shelf_obj.set("Abteilung", shelf.abteilung ? shelf.abteilung : "");
      shelf_obj.set("Art", shelf.art ? shelf.art : "");
      shelf_obj.set("customer", shelf.customer ? shelf.customer : "");
      shelf_obj.set(
        "shelfcompany",
        shelf.shelfcompany ? shelf.shelfcompany : ""
      );

      await shelf_obj.save();
      setShelf({
        id: "XXX",
        name: "",
        betriebstatte: "",
        nummer: "",
        abteilung: "",
        art: "",
        customer: "",
        shelfcompany: "",
      });

      notification.open({
        message: "Erfolgreich gespeichert",
        description: "Das Regal wurde erfolgreich gespeichert!",
        onClick: () => {},
      });
      window.history.back();
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchCustomer();
  }, []);

  return (
    React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      , React.createElement(PageHeader, {
        onBack: () => {
          window.history.back();
        },
        title: "Regal Management",
        subTitle: "Regal von Kunden anlegen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
      )
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        , React.createElement('div', { style: {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          , React.createElement('h3', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Kundenauswahl:")
          , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
            , React.createElement(Select, {
              placeholder: "Kunden auswählen" ,
              style: { width: "100%", height: "48px !important" },
              value: customer.id,
              onChange: (e) => {
                try {
                  if (e == "XXX") {
                    setCustomer({
                      id: "XXX",
                      name: "",
                      contact: "",
                      contacttel: "",
                      saftycontact: "",
                    });
                    setShelf({
                      id: shelf.id,
                      name: shelf.name,
                      betriebstatte: shelf.betriebstatte,
                      nummer: shelf.nummer,
                      abteilung: shelf.abteilung,
                      art: shelf.art,
                      customer: customer.id,
                      shelfcompany: shelf.shelfcompany,
                    });
                  } else {
                    let selectedObject = customerList.filter(
                      (x) => x.objectId === e
                    );
                    setCustomer({
                      id: selectedObject[0].objectId,
                      name: selectedObject[0].name,
                      contact: selectedObject[0].contact,
                      contacttel: selectedObject[0].contacttel,
                      saftycontact: selectedObject[0].saftycontact,
                    });
                    setShelf({
                      id: shelf.id,
                      name: shelf.name,
                      betriebstatte: shelf.betriebstatte,
                      nummer: shelf.nummer,
                      abteilung: shelf.abteilung,
                      art: shelf.art,
                      customer: selectedObject[0].objectId,
                      shelfcompany: shelf.shelfcompany,
                    });
                  }
                } catch (e) {
                  setCustomer({
                    id: "XXX",
                    name: "",
                    contact: "",
                    contacttel: "",
                    saftycontact: "",
                  });
                }
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
            
              , customerList.length > 0 &&
                customerList.map((item) => (
                  React.createElement(Select.Option, { key: item.objectId, value: item.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
                    , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}, item.name)
                  )
                ))
            )
          )
        )
      )

      , customer != "" && (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
          , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
            , React.createElement('h3', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}, "Regalinformationen:")

            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
              , React.createElement(Input, {
                placeholder: "Regalbezeichner",
                style: { width: "100%" },
                value: shelf.name,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: val,
                    betriebstatte: shelf.betriebstatte,
                    nummer: shelf.nummer,
                    abteilung: shelf.abteilung,
                    art: shelf.art,
                    customer: customer.id,
                    shelfcompany: shelf.shelfcompany,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}
              , React.createElement(Input, {
                placeholder: "Hersteller",
                style: { width: "100%" },
                value: shelf.shelfcompany,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: shelf.name,
                    betriebstatte: shelf.betriebstatte,
                    nummer: shelf.nummer,
                    abteilung: shelf.abteilung,
                    art: shelf.art,
                    customer: customer.id,
                    shelfcompany: val,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}
              , React.createElement(Input, {
                placeholder: "Regalnummer",
                style: { width: "100%" },
                value: shelf.nummer,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: shelf.name,
                    betriebstatte: shelf.betriebstatte,
                    nummer: val,
                    abteilung: shelf.abteilung,
                    art: shelf.art,
                    customer: customer.id,
                    shelfcompany: shelf.shelfcompany,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
              , React.createElement(Input, {
                placeholder: "Regalart",
                style: { width: "100%" },
                value: shelf.art,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: shelf.name,
                    betriebstatte: shelf.betriebstatte,
                    nummer: shelf.nummer,
                    abteilung: shelf.abteilung,
                    art: val,
                    customer: customer.id,
                    shelfcompany: shelf.shelfcompany,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}
              , React.createElement(Input, {
                placeholder: "Betriebsstätte",
                style: { width: "100%" },
                value: shelf.betriebstatte,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: shelf.name,
                    betriebstatte: val,
                    nummer: shelf.nummer,
                    abteilung: shelf.abteilung,
                    art: shelf.art,
                    customer: customer.id,
                    shelfcompany: shelf.shelfcompany,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 253}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}
              , React.createElement(Input, {
                placeholder: "Abteilung",
                style: { width: "100%" },
                value: shelf.abteilung,
                onChange: (e) => {
                  const val = e.target.value;
                  setShelf({
                    id: shelf.id,
                    name: shelf.name,
                    betriebstatte: shelf.betriebstatte,
                    nummer: shelf.nummer,
                    abteilung: val,
                    art: shelf.art,
                    customer: customer.id,
                    shelfcompany: shelf.shelfcompany,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 273}}
              )
            )
          )

          , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 294}}
            , React.createElement(Button, {
              type: "primary",
              style: { marginTop: "10px", width: "49%", float: "left" },
              onClick: async () => {
                saveEntry();
              },
              disabled: shelf.nummer === "" || shelf.customer === "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}
            , "Speichern"

            )
            , React.createElement('div', { style: { height: "10px", width: "2%", float: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}})
            , React.createElement(Button, {
              style: { marginTop: "10px", width: "49%", float: "left" },
              onClick: async () => {
                /*
                setCustomer({
                  id: "",
                  customerName: "",
                  customerContactPerson: "",
                  customerContactTel: "",
                  customerSaftyPerson: "",
                });
                setShelf({
                  id: "XXX",
                  name: "",
                  betriebstatte: "",
                  nummer: "",
                  abteilung: "",
                  art: "",
                  customer: "",
                  shelfcompany: "",
                });*/
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 306}}
            , "Verwerfen"

            )
          )
          , shelf.nummer === "" || shelf.customer === "" ? (
            React.createElement('p', {
              style: {
                textAlign: "center",
                width: "100%",
                padding: "20px",
                color: "red",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}
            , "Es muss ein Kunde ausgewählt sowie eine Regalnummer angegeben werden!"


            )
          ) : (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 345}})
          )
        )
      )
    )
  );
}
