const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/forms/Forms.js";import React, { useState } from "react";
import produce from "immer";
import Parse from "parse";
import {
  Steps,
  Divider,
  PageHeader,
  Layout,
  Form,
  Button,
  Modal,
  Row,
  Col,
} from "antd";
import {
  RightOutlined,
  DeliveredProcedureOutlined,

} from "@ant-design/icons";
import { Route, useParams, useNavigate, useLocation } from "react-router-dom";

import FormPage from "./FormPage";
import config from "../config";

import Loading from "../components/Loading";

const { Step } = Steps;
const { Sider } = Layout;

function Forms(props) {
  const params = useParams();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [state, setState] = useState({
    formid: params.RID,
    entry: parseInt(params.entry),
    buttonVisible: true,
  });
  const formMetaInfo = {
    unit: params.unit,
    form: params.RID,
    reference: localStorage.getItem("openchecklistref"),
  };

  const [result, setResult] = useState({});

  const getForm = async () => {
    let qForm = new Parse.Query(config.forms.table);
    qForm.equalTo("RID", state.formid);
    let cForm = await qForm.first();
    if (!cForm) return;
    cForm = cForm.toJSON();
    let forms = [];
    for (let entry of cForm.entries) {
      let qPage = new Parse.Query(config.pages.table);
      qPage.equalTo("objectId", entry);
      let page = await qPage.first();
      if (page) {
        forms.push(page.toJSON());
      }
    }
    cForm.forms = forms;
    setState(
      produce((draft) => {
        draft.form = cForm;
      })
    );
  };
  React.useEffect(() => {
    if (state.formid) {
      getForm();
    } else {
      //navigate
    }
  }, [state.formid]);
  const [form] = Form.useForm();
  const next = (step) => {
    navigate(
      "/form/" +
        params.unit +
        "/" +
        params.RID +
        "/" +
        (parseInt(params.entry) + 1) +
        search
    );
  };

  const previous = () => {
    if (parseInt(params.entry) > 0) {
      navigate(
        "/form/" +
          params.unit +
          "/" +
          params.RID +
          "/" +
          (parseInt(params.entry) - 1) +
          search
      );
    } else {
      props.OnCancel();
    }
  };

  const setExtras = (extras) => {
    setState(
      produce((draft) => {
        draft.extras = extras;
      })
    );
  };
  const setButtonVisible = (visibility) => {
    if (!("" + visibility === "true" || "" + visibility === "false")) {
      return state.buttonVisible;
    }
    setState(
      produce((draft) => {
        draft.buttonVisible = visibility;
      })
    );
  };

  const showModal = () => {
    setState(
      produce((draft) => {
        draft.visible = true;
      })
    );
  };

  const hideModal = () => {
    setState(
      produce((draft) => {
        draft.visible = false;
      })
    );
  };

  const hideModalSave = async () => {
    try {
      const values = await form.validateFields();
      props.OnStepFinished(parseInt(params.entry), values);
      props.OnFormFinished({
        formInfo: formMetaInfo,
        fields: { ...result, ...values },
        form: state.form,
      });
      setState(
        produce((draft) => {
          draft.visible = false;
        })
      );
    } catch (errorInfo) {
      console.error(errorInfo);
    }
  };

  const buttonSave = () => {
    setState(
      produce((draft) => {
        draft.ref = localStorage.getItem("openchecklistref");
        draft.user = Parse.User.current().username;
      })
    );

    showModal();
  };

  const button = () => {
    if (!state.buttonVisible) {
      return "";
    }
    if (parseInt(params.entry) < state.form.forms.length - 1) {
      return (
        React.createElement('div', { className: "form-footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 176}})
          , React.createElement(Button, {
            type: "primary",
            className: "form-button-next",
            icon: React.createElement(RightOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 180}} ),
            onClick: async (e) => {
              try {
                const values = await form.validateFields();
                props.OnStepFinished(parseInt(params.entry), values);
                setResult({ ...result, ...values });
                next();
              } catch (errorInfo) {}
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
          , "Weiter"

          )
        )
      );
    } else {
      return (
        React.createElement('div', { className: "form-footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 197}})
          , React.createElement(Button, {
            type: "primary",
            className: "form-button-next",
            icon: React.createElement(DeliveredProcedureOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 201}} ),
            onClick: async (e) => {
              buttonSave();
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}
          , "Abschließen"

          )
        )
      );
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  if (!state.form) {
    return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 224}});
  }
  return (
    React.createElement('div', { className: "App", size: "small", direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
      , React.createElement(Route, {
        path: ":entry",
        element: 
          React.createElement(Layout, { className: "fullHeight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
            , React.createElement(Sider, {
              width: "20vw",
              collapsible: true,
              breakpoint: "lg",
              collapsedWidth: "0",
              theme: "light",
              trigger: null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
            
              , React.createElement('div', {
                style: {
                  padding: "10px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
              
                , React.createElement(Steps, { direction: "vertical", current: parseInt(params.entry), __self: this, __source: {fileName: _jsxFileName, lineNumber: 245}}
                  , state.form.forms.map((value, index) => {
                    return (
                      React.createElement(Step, {
                        key: value.objectId,
                        title: value.data.title,
                        description: value.data.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 248}}
                      )
                    );
                  })
                )
              )
            )
            , React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
              , React.createElement(PageHeader, {
                onBack: previous,
                title: state.form.forms[params.entry].data.title,
                style: { background: "#fff" },
                extra: state.extras, __self: this, __source: {fileName: _jsxFileName, lineNumber: 259}}
              
                , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
                  , React.createElement(Col, { xs: 16, __self: this, __source: {fileName: _jsxFileName, lineNumber: 266}}
                    , state.form.forms[params.entry].data.description
                  )
                  , React.createElement(Col, { xs: 8, style: { textAlign: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 269}}
                    , config.referenceStrings[4], ": " , formMetaInfo.reference
                  )
                )
              )
              , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 274}})
              , React.createElement(Form, {
                form: form,
                className: "field-holder fullHeight" ,
                size: "large",
                scrollToFirstError: true,
                labelAlign: "left",
                ...formItemLayout, __self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}
              
                , React.createElement(FormPage, {
                  Data: state.form,
                  FormData: form,
                  SetExtras: setExtras,
                  ShowNextButton: setButtonVisible, __self: this, __source: {fileName: _jsxFileName, lineNumber: 283}}
                )

                , button()
              )
            )
            , React.createElement(Modal, {
              title: "Daten absenden" ,
              visible: state.visible,
              onOk: hideModalSave,
              onCancel: hideModal,
              okText: "Senden",
              cancelText: "Abbrechen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 293}}
            , "Bitte bestätigen Sie, dass Sie das aktuelle Formular zurückmelden möchten:"


              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 303}} ), " - "  , config.referenceStrings[0], " " , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}, state.ref), " " , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 303}} ), "- Nutzer "
               , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 304}}, state.user)
            )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
      )
    )
  );
}

export default Forms;
