const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/index.js";import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import { registerIconPack, setIconClassName } from "@opendash/icons";

registerIconPack(require("@opendash/icons/dist/fa-regular.json"));
setIconClassName("anticon");

ReactDOM.render(
  React.createElement(React.StrictMode, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    , React.createElement(Router, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , React.createElement(Main, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
    )
  ),
  document.getElementById("opendash")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
