const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/custom_wehn/ArtikelBuchungDrawerComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse from "parse";
import produce from "immer";


import { useSwipeable } from "react-swipeable";
import {
  Space,
  Select,
  Button,
  InputNumber,
  Drawer,
  Divider,
  List,
  Tag,
  Modal,
  Result,
  Tabs,
  Badge,
  Input,

  Alert,
  Table,
  Collapse,

} from "antd";

import Loading from "../components/Loading";

import { Icon } from "@opendash/icons";



export default function ArtikelBuchungDrawerComponent(props) {
  const [state, setState] = React.useState({
    artikel: null,

    bestaende: {},
    selectedBestandId: undefined,
    einlagerungen: {},
    selectedEinlagerungId: undefined,

    seriennummer: "",
    charge: "",

    loading: true,

    currentTab: 1,
  });
  const [removeValue, setRemoveValue] = React.useState(1);
  const [removalStockValid, setRemovalStockValid] = React.useState({
    valid: false,
    stock: null,
    location: null,
  });
  const [previousStocks, setPreviousStocks] = React.useState([]);
  // console.log("ArtikelBuchungDrawerComponent state", state);
  // console.log("ArtikelBuchungDrawerComponent props", props);

  const removals = (props.removals ? [...props.removals] : []).sort((a, b) => {
    let dateA = new Date(a.updatedAt);
    let dateB = new Date(b.updatedAt);
    return dateB.getTime() - dateA.getTime();
  });

  const update = (dataupdate) => {
    setState(
      produce((draft) => {
        draft.loading = true;
        draft.charge = "";
        draft.seriennummer = "";
      })
    );
    props.update(dataupdate);
  };

  const setCurrentTab = (tab) => {
    setState(
      produce((draft) => {
        draft.currentTab = tab;
      })
    );
  };

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.loading = false;
      })
    );
  }, [props.removals]);

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.loading = true;
        draft.currentTab = 1;
        draft.artikel = null;
        draft.seriennummer = "";
        draft.charge = "";
      })
    );
    if (props.selectedItem) {
      const mengeSoll =
        props.selectedItem.mengeSoll || props.selectedItem.menge;
      const mengeIst = props.selectedItem.mengeIst || 0;
      const mengeFehlt = Math.max(mengeSoll - mengeIst, 0);
      setRemoveValue(mengeFehlt);
      getLagerDetails();
      getPreviousStocks();
    }
  }, [props.selectedItem]);

  const confirmRemoval = (
    item,
    bestandOrEinlagerung,
    amount,
    lager,
    lagerort
  ) => {
    let ungeplant = false;

    if ("mengeSoll" in item && "mengeIst" in item) {
      if (item.mengeSoll <= item.mengeIst) {
        ungeplant = true;
      }
    }
    let icon = React.createElement(Icon, { icon: "fa:exclamation-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}} );
    let okButtonProps = {};
    if (!ungeplant) {
      icon = React.createElement(Icon, { icon: "fa:check-circle", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}} );
      okButtonProps.style = {
        backgroundColor: "green",
        color: "white",
        borderColor: "darkgreen",
      };
    } else {
      icon = React.createElement(Icon, { icon: "fa:exclamation-circle", style: { color: "#faad14" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}} );
      okButtonProps.style = {
        backgroundColor: "#ffe58f",
        color: "#faad14",
        borderColor: "#faad14",
      };
    }
    const dateEntryStyle = { float: "right" };
    Modal.confirm({
      title: amount > 0 ? "Entnahme " : "Rücklagerung " + item.artikelnr,
      icon,
      okButtonProps,
      okText:
        "" +
        Math.abs(amount) +
        " " +
        _optionalChain([state, 'access', _ => _.artikel, 'optionalAccess', _2 => _2.mengeneinheit]) +
        (amount < 0 ? " rücklagern" : " entnehmen"),
      cancelText: "abbrechen",
      content: (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}, item.bezeichnung)
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}, "Geplante Menge:"
             , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
              , item.mengeSoll || item.menge, " " , _optionalChain([state, 'access', _3 => _3.artikel, 'optionalAccess', _4 => _4.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}, "Bereits entnommen:"
             , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
              , item.mengeIst || 0, " " , _optionalChain([state, 'access', _5 => _5.artikel, 'optionalAccess', _6 => _6.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 174}} )
          , amount > 0 && (
            React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}, "Lager-Verfügbarkeit :"
               , " "
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                , bestandOrEinlagerung.menge ||
                  bestandOrEinlagerung.bestandLaufend, " "
                , _optionalChain([state, 'access', _7 => _7.artikel, 'optionalAccess', _8 => _8.mengeneinheit])
              )
            )
          )
          , amount < 0 && (
            React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}, "Lager:"
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}, _optionalChain([lager, 'optionalAccess', _9 => _9.sqlRef]) || "")
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 190}} )
          , amount < 0 && (
            React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}, "Lagerort:"
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}, _optionalChain([lagerort, 'optionalAccess', _10 => _10.lagerort]) || "")
            )
          )
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 196}})
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}, "Entnahme:"
            , " "
            , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
              , amount, " " , _optionalChain([state, 'access', _11 => _11.artikel, 'optionalAccess', _12 => _12.mengeneinheit])
            )
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 203}} )
        )
      ),
      onOk() {
        const removal = produce(item, (draft) => {
          draft.lagerort = lagerort;
          draft.lager = lager;
          draft.menge = amount;
          if (ungeplant) draft.position = "ungeplant";
          if (state.seriennummer) {
            draft.seriennummer = state.seriennummer;
          }

          if (state.charge) {
            draft.charge = state.charge;
          }
        });

        let dataUpdate = {
          removals: [removal],
          restocked: [],
        };
        update(dataUpdate);
      },
    });
  };

  const getPreviousStocks = async () => {
    const artikel = new Parse.Object("Artikel", {
      id: props.selectedItem.artikel.objectId,
    });

    await artikel.fetch();

    let previousStockQuery = await new Parse.Query("open_transaction_artikel")
      .equalTo("artikel", artikel)
      .equalTo("successful", true)
      .include("lager")
      .include("lagerort")
      .find();
    const x = {};
    previousStockQuery.forEach((stock) => {
      const jsonStock = stock.toJSON();
      const lager = _optionalChain([jsonStock, 'access', _13 => _13.lager, 'optionalAccess', _14 => _14.sqlRef]) || "";
      const lagerOrt = _optionalChain([jsonStock, 'access', _15 => _15.lagerort, 'optionalAccess', _16 => _16.lagerort]) || "";
      x[lager + " / " + lagerOrt] = true;
    });

    setPreviousStocks(Object.keys(x));
  };
  const getLagerDetails = async () => {
    const artikel = new Parse.Object("Artikel", {
      id: props.selectedItem.artikel.objectId,
    });

    await artikel.fetch();

    let bestaende = await new Parse.Query("ArtikelBestand")
      .equalTo("artikel", artikel)
      .greaterThan("bestandLaufend", 0)
      .include("lager")
      .find();

    let einlagerungen = await new Parse.Query("ArtikelEinlagerung")
      .equalTo("artikel", artikel)
      .greaterThan("menge", 0)
      .include("lager")
      .include("lagerort")
      .find();

    bestaende = Object.fromEntries(
      bestaende.map((parseObject) => [parseObject.id, parseObject.toJSON()])
    );
    einlagerungen = Object.fromEntries(
      einlagerungen.map((parseObject) => [parseObject.id, parseObject.toJSON()])
    );

    const selectedBestandId = Object.keys(bestaende)[0];

    // console.log("bestaende", bestaende);
    // console.log("einlagerungen", einlagerungen);
    // console.log("selectedBestandId", selectedBestandId);

    setState(
      produce((draft) => {
        draft.artikel = artikel.toJSON();
        draft.bestaende = bestaende;
        draft.selectedBestandId = selectedBestandId;
        draft.einlagerungen = einlagerungen;
        draft.selectedEinlagerungId = undefined;

        draft.loading = false;
        draft.stockPanel = "0";
      })
    );
  };
  const tabSwipeHandler = useSwipeable({
    onSwipedRight: (eventData) => {
      setCurrentTab(Math.max(state.currentTab - 1, 1));
    },
    onSwipedLeft: (eventData) => {
      setCurrentTab(Math.min(state.currentTab + 1, 2));
    },
  });

  const selectedBestand = React.useMemo(() => {
    if (state.bestaende[state.selectedBestandId]) {
      setRemovalStockValid({
        valid: true,
        lager: state.bestaende[state.selectedBestandId].lager,
      });
    } else {
      setRemovalStockValid({ valid: false });
    }

    return state.bestaende[state.selectedBestandId];
  }, [state.selectedBestandId]);

  const selectedEinlagerung = React.useMemo(() => {
    if (state.einlagerungen[state.selectedEinlagerungId]) {
      setRemovalStockValid({
        valid: true,
        lager: state.einlagerungen[state.selectedEinlagerungId].lager,
        location: state.einlagerungen[state.selectedEinlagerungId].lagerort,
      });
    } else {
      setRemovalStockValid({ valid: false });
    }

    return state.einlagerungen[state.selectedEinlagerungId];
  }, [state.selectedEinlagerungId]);

  const bestaendeSelectOptions = React.useMemo(
    () =>
      Object.values(state.bestaende).map((x) => ({
        label: ` ${x.lager.sqlRef} - ${x.bestandLaufend} ${_optionalChain([state, 'access', _17 => _17.artikel, 'optionalAccess', _18 => _18.mengeneinheit])}`,
        value: x.objectId,
      })),
    [state.bestaende]
  );

  const einlagerungenSelectOptions = React.useMemo(
    () =>
      state.selectedBestandId
        ? Object.values(state.einlagerungen)
            .filter((x) => x.lager.objectId === selectedBestand.lager.objectId)
            .map((x) => ({
              label: `Lagerort ${x.lager.sqlRef} / ${x.lagerort.lagerort} - ${x.menge} ${_optionalChain([state, 'access', _19 => _19.artikel, 'optionalAccess', _20 => _20.mengeneinheit])}`,
              value: x.objectId,
            }))
        : [],
    [state.einlagerungen, selectedBestand]
  );

  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.selectedEinlagerungId = _optionalChain([einlagerungenSelectOptions, 'access', _21 => _21[0], 'optionalAccess', _22 => _22.value]);
      })
    );
  }, [einlagerungenSelectOptions]);

  if (!props.selectedItem) {
    return null;
  }

  const getEntnahmeComponent = (restockFlag) => {
    return (
      React.createElement(React.Fragment, null
        , !restockFlag && (
          React.createElement(React.Fragment, null
            , bestaendeSelectOptions.length === 0 && (
              React.createElement(Result, {
                status: "warning",
                title: "Artikel ist nicht mehr auf Lager"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 375}}
              )
            )

            , bestaendeSelectOptions.length > 0 && (
              React.createElement(Select, {
                size: "large",
                style: { width: "100%" },
                options: bestaendeSelectOptions,
                value: state.selectedBestandId,
                onChange: (id) => {
                  setState(
                    produce((draft) => {
                      draft.selectedBestandId = id;
                    })
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}}
              )
            )

            , einlagerungenSelectOptions.length > 0 && (
              React.createElement(Select, {
                size: "large",
                style: { width: "100%", marginTop: 10 },
                options: einlagerungenSelectOptions,
                value: state.selectedEinlagerungId,
                onChange: (id) => {
                  setState(
                    produce((draft) => {
                      draft.selectedEinlagerungId = id;
                    })
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 398}}
              )
            )
          )
        )
        , restockFlag && (
          React.createElement(React.Fragment, null
            , React.createElement(Input, {
              placeholder: "Lager scannen" ,
              allowClear: true,
              style: { width: "100%" },
              onChange: async (e) => {
                const lagerqr = e.target.value;
                const lagerParts =
                  lagerqr.length === 12
                    ? [lagerqr.slice(0, 6), lagerqr.slice(6, 12)]
                    : lagerqr.length === 6
                    ? [lagerqr]
                    : lagerqr.length === 13 && lagerqr.includes("/")
                    ? lagerqr.split("/")
                    : [];

                try {
                  if (lagerParts.length === 1) {
                    const lager = await new Parse.Query("Lager")
                      .equalTo("sqlRef", lagerParts[0])
                      .first();
                    if (lager) {
                      setRemovalStockValid({
                        valid: true,
                        stock: lager.toJSON(),
                      });
                    } else {
                      throw new Error("Kein Lager gefunden");
                    }
                  } else if (lagerParts.length === 2) {
                    const lager = await new Parse.Query("Lager")
                      .equalTo("sqlRef", lagerParts[0])
                      .first();
                    const lagerOrt = await new Parse.Query("Lagerort")
                      .equalTo("sqlRef", JSON.stringify(lagerParts))
                      .first();
                    if (!lager || !lagerOrt) {
                      throw new Error("Lager oder Lagerort nicht gefudnen");
                    }

                    setRemovalStockValid({
                      valid: true,
                      stock: lager.toJSON(),
                      position: lagerOrt.toJSON(),
                    });
                  } else {
                    throw new Error("Unbekanntes Format");
                  }
                } catch (err) {
                  setRemovalStockValid({ valid: false });
                }
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 416}}
            )
          )
        )

        , (selectedBestand || restockFlag) && (
          React.createElement(React.Fragment, null
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 473}})
            , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 474}}
              , _optionalChain([state, 'access', _23 => _23.artikel, 'optionalAccess', _24 => _24.chargenverfolgung]) && (
                React.createElement(Input, {
                  placeholder: "Chargennummer",
                  style: { width: "100%" },
                  value: state.charge,
                  onChange: (e) => {
                    const val = e.target.value;
                    setState(
                      produce((draft) => {
                        draft.charge = val;
                      })
                    );
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 476}}
                )
              )

              , _optionalChain([state, 'access', _25 => _25.artikel, 'optionalAccess', _26 => _26.seriennummer]) && (
                React.createElement(React.Fragment, null
                  , React.createElement(Input, {
                    placeholder: "Seriennummer",
                    style: { width: "100%" },
                    value: state.seriennummer,
                    onChange: (e) => {
                      const val = e.target.value;
                      setState(
                        produce((draft) => {
                          draft.seriennummer = val;
                        })
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 493}}
                  )
                  , React.createElement(Button, {
                    width: "100%",
                    type: "primary",
                    size: "large",
                    style: { width: "100%" },
                    disabled: 
                      restockFlag
                        ? 1 > props.selectedItem.mengeIst ||
                          !removalStockValid.valid
                        : 1 >
                          (_optionalChain([selectedEinlagerung, 'optionalAccess', _27 => _27.menge]) ||
                            selectedBestand.bestandLaufend)
                    ,
                    onClick: () => {
                      confirmRemoval(
                        props.selectedItem,
                        selectedEinlagerung || selectedBestand,
                        restockFlag ? -1 : 1,
                        (selectedEinlagerung || selectedBestand).lager,
                        _optionalChain([selectedEinlagerung, 'optionalAccess', _28 => _28.lagerort])
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 506}}
                  , "Artikel mit Seriennummer entnehmen"

                  )
                )
              )

              , !_optionalChain([state, 'access', _29 => _29.artikel, 'optionalAccess', _30 => _30.seriennummer]) && (
                React.createElement(React.Fragment, null
                  , React.createElement(InputNumber, {
                    type: "number",
                    value: removeValue,
                    onChange: (test) => {
                      if (test === null) {
                        setRemoveValue(0);
                      } else {
                        setRemoveValue(test);
                      }
                    },
                    placeholder: "Zu entnehmende Menge"  ,
                    style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 536}}
                  )

                  , (restockFlag
                    ? removeValue === 0 ||
                      removeValue > props.selectedItem.mengeIst
                    : removeValue === 0 ||
                      removeValue >
                        (_optionalChain([selectedEinlagerung, 'optionalAccess', _31 => _31.menge]) ||
                          selectedBestand.bestandLaufend)) && (
                    React.createElement(Alert, { message: "Bitte Menge kontrollieren"  , type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 557}} )
                  )

                  , React.createElement(Button, {
                    type: "primary",
                    size: "large",
                    style: { width: "100%" },
                    disabled: 
                      restockFlag
                        ? removeValue === 0 ||
                          removeValue > props.selectedItem.mengeIst ||
                          !removalStockValid.valid
                        : removeValue === 0 ||
                          removeValue >
                            (_optionalChain([selectedEinlagerung, 'optionalAccess', _32 => _32.menge]) ||
                              selectedBestand.bestandLaufend)
                    ,
                    onClick: () => {
                      const lager = restockFlag
                        ? removalStockValid.stock
                        : (selectedEinlagerung || selectedBestand).lager;
                      const lagerOrt = restockFlag
                        ? removalStockValid.position
                        : _optionalChain([selectedEinlagerung, 'optionalAccess', _33 => _33.lagerort]);
                      confirmRemoval(
                        props.selectedItem,
                        selectedEinlagerung || selectedBestand,
                        restockFlag ? removeValue * -1 : removeValue,
                        lager,
                        lagerOrt
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 560}}
                  
                    , restockFlag ? "Rücklagern" : "Entnehmen"
                  )
                )
              )
            )
          )
        )
      )
    );
  };
  return (
    React.createElement(Drawer, {
      placement: "bottom",
      title: "Entnehmen von " + (_optionalChain([props, 'access', _34 => _34.selectedItem, 'optionalAccess', _35 => _35.artikelnr]) || ""),
      visible: !!props.selectedItem,
      closable: true,
      onClose: () => {
        props.onClose();
      },
      height: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 601}}
    
      , state.loading ? (
        React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 612}} )
      ) : (
        React.createElement('div', {
          size: "small",
          align: "center",
          direction: "vertical",
          style: { width: "100%" },
          ...tabSwipeHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 614}}
        
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 621}}, _optionalChain([props, 'access', _36 => _36.selectedItem, 'optionalAccess', _37 => _37.bezeichnung]))

          , React.createElement(Collapse, { expandIconPosition: "right", ghost: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 623}}
            , React.createElement(Collapse.Panel, { header: "Einlagerungen & Bestände"  , key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 624}}
              , React.createElement(List, {
                header: React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 626}}, "Einlagerungen"),
                bordered: true,
                dataSource: einlagerungenSelectOptions,
                renderItem: (item) => React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 629}}, item.label), __self: this, __source: {fileName: _jsxFileName, lineNumber: 625}}
              )

              , React.createElement(List, {
                header: React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 633}}, "Bestände"),
                bordered: true,
                style: { marginTop: "10px" },
                dataSource: bestaendeSelectOptions,
                renderItem: (item) => React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 637}}, item.label), __self: this, __source: {fileName: _jsxFileName, lineNumber: 632}}
              )

              , React.createElement(List, {
                header: React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 641}}, "Bisherige Entnahmeorte" ),
                bordered: true,
                style: { marginTop: "10px" },
                dataSource: previousStocks,
                renderItem: (item) => React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 645}}, item), __self: this, __source: {fileName: _jsxFileName, lineNumber: 640}}
              )
            )
          )
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 649}} )
          , React.createElement(Tabs, {
            activeKey: "" + state.currentTab,
            onTabClick: (key, event) => {
              setCurrentTab(parseInt(key));
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 650}}
          
            , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 656}}, "Entnahme"), key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 656}}
              , getEntnahmeComponent(false)
            )
            , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 659}}, "Rücklagerung"), key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 659}}
              , getEntnahmeComponent(true)
            )
            , React.createElement(Tabs.TabPane, {
              tab: 
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 664}}, "Bereits entommen"
                   , " "
                  , React.createElement(Badge, {
                    count: props.selectedItem.mengeIst || 0,
                    style: { backgroundColor: "#52c41a" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 666}}
                  )
                )
              ,
              key: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 662}}
            
              , React.createElement(Table, {
                size: "small",
                dataSource: removals,
                columns: [
                  /*
                  {
                    title: "Zeit",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    render: (text) => (
                      <p>
                        {moment(text).format("LTS")}
                        <br />
                        {moment(text).format("L")}
                      </p>
                    ),
                  },*/
                  {
                    title: "Nutzer",
                    dataIndex: "user",
                    key: "user",
                    render: (user) => (
                      React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 696}}, _optionalChain([user, 'optionalAccess', _38 => _38.amsCardCode]) || "Unbekannter Nutzer")
                    ),
                  },
                  {
                    title: "Art",
                    dataIndex: "menge",
                    key: "mennge",
                    render: (menge) => (
                      React.createElement(Tag, { color: menge > 0 ? "green" : "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 704}}
                        , menge > 0 ? "E" : "R"
                      )
                    ),
                  },
                  {
                    title: "Menge",
                    dataIndex: "menge",
                    key: "menge",
                    render: (menge) => (
                      React.createElement('b', {
                        style: {
                          color: menge < 0 ? "red" : "green",
                          float: "right",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 714}}
                      
                        , menge
                      )
                    ),
                  },
                  {
                    title: "Einheit",
                    dataIndex: ["artikel", "mengeneinheit"],
                    key: "einheit",
                    render: (menge) => React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 728}}, menge),
                  },
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 674}}
              )

              /*
              <List
                style={{ background: "#fff", padding: "12px" }}
                itemLayout="vertical"
                dataSource={removals}
                renderItem={(item) => (
                  <List.Item
                    actions={
                      item.menge > 0 && !isRemoval(item, removals)
                        ? [
                            <Button
                              onClick={() => {
                                setRestock(item);
                              }}
                            >
                              wieder einlagern
                            </Button>,
                          ]
                        : [!item.undo && <Tag>Zurückgelagert</Tag>]
                    }
                  >
                    <List.Item.Meta
                      title={
                        item.artikel
                          ? item.artikel?.artikelnummer +
                            " - " +
                            item.artikel?.bezeichnung
                          : "Artikel"
                      }
                      description={
                        <span>
                          {item.menge > 0
                            ? `Entnommen: ${Math.abs(item.menge)}`
                            : `Zurück eingelagert: ${Math.abs(item.menge)}`}

                          {item.seriennummer && (
                            <>
                              <br />
                              {`Seriennummer: ${item.seriennummer}`}
                            </>
                          )}
                          {item.charge && (
                            <>
                              <br />
                              {`Charge: ${item.charge}`}
                            </>
                          )}
                        </span>
                      }
                    />
                  </List.Item>
                )}
              /> */
            )
          )
        )
      )

      /*
      <Modal
        visible={!!restock}
        title="Rücklagerung"
        okButtonProps={{ disabled: !removalStockValid.valid }}
        onOk={() => {
          update({
            restocked: [
              {
                objectId: restock.objectId,
                menge: restockAmount,
                lager: removalStockValid.lager,
                lagerort: removalStockValid.location,
              },
            ],
            removals: [],
          });

          setRestock(null);
          setRestockAmount(1);
        }}
        onCancel={() => {
          setRestock(null);
          setRestockAmount(1);
        }}
        okText="Einlagern"
        cancelText="Abbrechen"
      >
        {restock && (
          <>
            <Descriptions layout="horizontal" bordered column={1}>
              <Descriptions.Item
                label="Entnommen"
                children={
                  Math.abs(restock.menge) + " " + state.artikel?.mengeneinheit
                }
              />

              {restock.seriennummer && (
                <Descriptions.Item
                  label="Seriennummer"
                  children={restock.seriennummer}
                />
              )}

              {restock.charge && (
                <Descriptions.Item label="Charge" children={restock.charge} />
              )}
            </Descriptions>

            <Divider />

            <p>
              Wie viel ({state.artikel?.mengeneinheit}) soll rückgelagert
              werden?
            </p>

            <Input
              placeholder="Lager scannen"
              style={{ width: "100%" }}
              onChange={async (e) => {
                const lagerqr = e.target.value;
                const lagerParts = lagerqr.split("/");
                if (lagerParts.length === 1) {
                  try {
                    const lager = await new Parse.Query("Lager")
                      .equalTo("sqlRef", lagerParts[0])
                      .first();
                    if (lager) {
                      setRemovalStockValid({
                        valid: true,
                        stock: lager,
                      });
                    } else {
                      throw new Error("Kein Lager gefunden");
                    }
                  } catch (err) {
                    setRemovalStockValid({ valid: false });
                  }
                } else {
                  try {
                    const lager = await new Parse.Query("Lager")
                      .equalTo("sqlRef", lagerParts[0])
                      .first();
                    const lagerOrt = await new Parse.Query("Lagerort")
                      .equalTo("sqlRef", JSON.stringify(lagerParts))
                      .first();
                    if (!lager || !lagerOrt) {
                      throw new Error("Lager oder Lagerort nicht gefudnen");
                    }
                    setRemovalStockValid({
                      valid: true,
                      stock: lager,
                      position: lagerOrt,
                    });
                  } catch (err) {
                    setRemovalStockValid({ valid: false });
                  }
                }
              }}
            />
            <InputNumber
              min={1}
              max={restock.menge}
              value={restockAmount}
              onChange={(val) => {
                setRestockAmount(Math.max(1, Math.min(val, restock.menge)));
              }}
              placeholder="Menge"
              style={{ width: "100%" }}
            />
          </>
        )}
      </Modal>
      */
    )
  );
}
