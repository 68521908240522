const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/custom_arfa/CustomerComponent.js";import React, { } from "react";
import Parse, { } from "parse";

import { useParams, } from "react-router-dom";
import { Input, PageHeader, Select, Button } from "antd";

export default function CustomerComponent(props) {
  const params = useParams();
  const [customerList, setCustomerList] = React.useState([]);
  const [customer, setCustomer] = React.useState({
    id: "XXX",
    name: "",
    contact: "",
    contacttel: "",
    saftycontact: "",
  });

  const fetchCustomer = async () => {
    try {
      const cCustomer = new Parse.Query("ARFA_CUSTOMER");
      const customers = await cCustomer.find();
      let resultsArray = [];
      for (let i = 0; i < customers.length; i++) {
        resultsArray.push(customers[i].toJSON());
      }
      setCustomerList(resultsArray);
    } catch (error) {
      setCustomerList(null);
    }
  };

  const updateEntry = async (id) => {
    try {
      const cCustomer = await new Parse.Query("ARFA_CUSTOMER")
        .equalTo("objectId", id)
        .first();
      customer_obj.set("name", customer.name ? customer.name : "");
      customer_obj.set("contact", customer.contact ? customer.contact : "");
      customer_obj.set(
        "contacttel",
        customer.contacttel ? customer.contacttel : ""
      );
      customer_obj.set(
        "saftycontact",
        customer.saftycontact ? customer.saftycontact : ""
      );
      await cCustomer.save();
      setCustomer({
        id: "XXX",
        customerName: "",
        customerContactPerson: "",
        customerContactTel: "",
        customerSaftyPerson: "",
      });
    } catch (error) {}
  };

  const saveEntry = async () => {
    try {
      const Customer = Parse.Object.extend("ARFA_CUSTOMER");
      const customer_obj = new Customer();

      customer_obj.set("name", customer.name ? customer.name : "");
      customer_obj.set("contact", customer.contact ? customer.contact : "");
      customer_obj.set(
        "contacttel",
        customer.contacttel ? customer.contacttel : ""
      );
      customer_obj.set(
        "saftycontact",
        customer.saftycontact ? customer.saftycontact : ""
      );

      await customer_obj.save();
      setCustomer({
        id: "XXX",
        customerName: "",
        customerContactPerson: "",
        customerContactTel: "",
        customerSaftyPerson: "",
      });
      notification.open({
        message: "Erfolgreich gespeichert",
        description: "Der Kunde wurde erfolgreich gespeichert!",
        onClick: () => {},
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchCustomer();
  }, []);

  return (
    React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
      , React.createElement(PageHeader, {
        onBack: () => {
          window.history.back();
        },
        title: "Kunden Management",
        subTitle: "Kunden anlegen oder bearbeiten", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
      )
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
        , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , React.createElement(Select, {
            placeholder: "Kunden auswählen zum bearbeiten oder neuen anlagen"      ,
            style: { width: "100%", height: "48px !important" },
            value: customer.id,
            onChange: (e) => {
              try {
                if (e == "XXX") {
                  setCustomer({
                    id: "XXX",
                    name: "",
                    contact: "",
                    contacttel: "",
                    saftycontact: "",
                  });
                } else {
                  let selectedObject = customerList.filter(
                    (x) => x.objectId === e
                  );

                  setCustomer({
                    id: selectedObject[0].objectId,
                    name: selectedObject[0].name,
                    contact: selectedObject[0].contact,
                    contacttel: selectedObject[0].contacttel,
                    saftycontact: selectedObject[0].saftycontact,
                  });
                }
              } catch (e) {
                setCustomer({
                  id: "XXX",
                  name: "",
                  contact: "",
                  contacttel: "",
                  saftycontact: "",
                });
              }
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          
            , React.createElement(Select.Option, {
              key: "newcustomer",
              value: "XXX",
              label: "Neuen Kunden anlegen"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
            
              , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}, "Neuen Kunden anlegen"  )
            )

            , customerList.length > 0 &&
              customerList.map((item) => (
                React.createElement(Select.Option, { key: item.objectId, value: item.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
                  , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, item.name)
                )
              ))
          )
        )
      )

      , customer != "" && (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
          , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
            , React.createElement('h3', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}, "Kundeninformationen:")
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
              , React.createElement(Input, {
                placeholder: "Kundenbezeichnung / Betreiber"  ,
                style: { width: "100%" },
                value: customer.name,
                onChange: (e) => {
                  const val = e.target.value;
                  setCustomer({
                    id: customer.id,
                    name: val,
                    contact: customer.contact,
                    contacttel: customer.contacttel,
                    saftycontact: customer.saftycontact,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
              , React.createElement(Input, {
                placeholder: "Ansprechpartner",
                style: { width: "100%" },
                value: customer.contact,
                onChange: (e) => {
                  const val = e.target.value;
                  setCustomer({
                    id: customer.id,
                    name: customer.name,
                    contact: val,
                    contacttel: customer.contacttel,
                    saftycontact: customer.saftycontact,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
              , React.createElement(Input, {
                placeholder: "Telefonnummer",
                style: { width: "100%" },
                value: customer.contacttel,
                onChange: (e) => {
                  const val = e.target.value;
                  setCustomer({
                    id: customer.id,
                    name: customer.name,
                    contact: customer.contact,
                    contacttel: val,
                    saftycontact: customer.saftycontact,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
              )
            )
            , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
              , React.createElement(Input, {
                placeholder: "Sicherheitsbeauftragter",
                style: { width: "100%" },
                value: customer.saftycontact,
                onChange: (e) => {
                  const val = e.target.value;
                  setCustomer({
                    id: customer.id,
                    name: customer.name,
                    contact: customer.contact,
                    contacttel: customer.contacttel,
                    saftycontact: val,
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
              )
            )
          )

          , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 235}}
            , React.createElement(Button, {
              type: "primary",
              style: { marginTop: "10px", width: "49%", float: "left" },
              onClick: async () => {
                if (customer.id != "XXX") {
                  updateEntry(customer.id);
                } else {
                  saveEntry();
                }
              },
              disabled: customer.name === "" || customer.contact === "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 236}}
            , "Speichern"

            )
            , React.createElement('div', { style: { height: "10px", width: "2%", float: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}})
            , React.createElement(Button, {
              style: { marginTop: "10px", width: "49%", float: "left" },
              onClick: async () => {
                setCustomer({
                  id: "XXX",
                  customerName: "",
                  customerContactPerson: "",
                  customerContactTel: "",
                  customerSaftyPerson: "",
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
            , "Verwerfen"

            )
          )
          , customer.name === "" || customer.contact === "" ? (
            React.createElement('p', {
              style: {
                textAlign: "center",
                width: "100%",
                padding: "20px",
                color: "red",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}
            , "Es muss mindestens ein Kundenbezeichner / Betreiber sowie ein Ansprechpartner angegeben werden!"


            )
          ) : (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 279}})
          )
        )
      )
    )
  );
}
