const _jsxFileName = "/Users/nico/Developer/openBDE/open.CHECKLIST/src/js/forms/components/InfoMessage.js";import React, { } from "react";

import { Card, Typography } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

function InfoMessage(props) {
  let style = { width: "100%" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  let state = props.Data;

  return (
    React.createElement(Card, {
      title: state.title,
      extra: React.createElement(InfoCircleOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} ),
      bordered: true,
      style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
    
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, state.hint)
    )
  );
}
export default InfoMessage;
